#iuDetail {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin-bottom: 20px;
    padding: 20px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    box-shadow: 0px 4px 12px var(--col-gray-1);
    transition: box-shadow 0.3s;
}

#iuDetail:hover {
    box-shadow: 0px 8px 14px var(--col-gray-1);
}

#iuDetail .compHeader {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

#iuDetail .compHeader .title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
}

#iuDetail .compHeader .func {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

#iuDetail .compHeader .func .info {
    flex: 1;
    font-size: 14px;
    color: var(--col-gray-4);
}

#iuDetail .compHeader .func .info strong {
    margin-left: 8px;

    font-weight: 700;
    color: var(--col-primary-5);
}

#iuDetail .compHeader .func .rowNum {
    padding: 2px 4px;
    border-radius: 4px;
    border: 1px solid var(--col-gray-2);
    outline: none;
    color: var(--col-black);
}

#iuDetail .compBody {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#iuDetail .rank {
    width: 100%;
}

#iuDetail .rank th {
    padding-bottom: 8px;
    text-align: center;
    font-size: 14px;
    color: var(--col-gray-4);
}

#iuDetail .rank th:last-child {
    min-width: 280px;
    width: 45%;
    max-width: 580px;
}

#iuDetail .rank tbody {
    border-radius: 8px;
    overflow: hidden;
}

#iuDetail .rank .item {
    padding: 8px 0;
}

#iuDetail .rank .item:nth-child(2n - 1) {
    background: var(--col-bg);
}

#iuDetail .rank .item:hover {
    background: var(--col-gray-1);
}

#iuDetail .rank .item td {
    padding: 8px 12px;
}

#iuDetail .rank .item td:nth-child(n + 3) {
    text-align: center;
}

#iuDetail .rank .item td a {
    text-decoration: none;
}

#iuDetail .rank .item td a:hover {
    text-decoration: underline;
}

#iuDetail .rank .item .no {
    width: 32px;
    text-align: center;
}

#iuDetail .rank .item .url {
    /* max-width: 480px; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 200px;
}

#iuDetail .rank .item .inflowNum {
    width: 48px;
}

#iuDetail .rank .item .ratio {
    display: flex;
    flex-direction: row;
    gap: 4px;
    min-width: 240px;
    max-width: 560px;
}

#iuDetail .rank .item .ratio .barWrap {
    display: inline-block;
    width: 100%;
    min-width: 200px;
    max-width: 520px;
    height: 12px;
    background: var(--col-gray-1);
    border: 1px solid var(--col-bg);
}

#iuDetail .rank .item .ratio .barWrap .bar {
    display: block;
    height: 12px;
    background: var(--col-primary-3);
}

#iuDetail .rank .item .ratio .value {
    width: 40px;
}

#iuDetail .compFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-top: 20px;
    width: 100%;
    margin-bottom: 12px;
}

#iuDetail .compFooter .pageBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-2);
    border-radius: 4px;
    color: var(--col-gray-4);
}

#iuDetail .compFooter .pageBtn svg {
    fill: var(--col-gray-4);
}

/* Mobile */
@media (max-width: 1200px) {
    #iuDetail {
        width: 100%;
        padding: 0;
    }

    #iuDetail .compHeader {
        padding: 1.5rem;
        padding-bottom: 0.5rem
    }

    #iuDetail .rank th:last-child {
        min-width: auto;
        max-width: none;
        width: auto;
    }

    #iuDetail .rank .item .ratio {
        min-width: auto;
        max-width: none;
    }

    #iuDetail .rank .item .ratio .value {
        width: 100%;
        text-align: center;
    }

    #iuDetail .compBody .rank .item .keyword {
        white-space: nowrap;
    }

    #iuDetail .compBody .rank .item .ratio .barWrap {
        display: none;
    }
}