.mobile_chart_title {
    width: 100%;

    display: flex;
    align-items: center;
}
.mobile_chart_title span {
    margin: 1.5rem;
    
    font-size: 1.5rem;
    font-weight: 700;
}

.mobile_chart_container .mobile_chart_legend {
    width: 100%;
    max-width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

/* 1200px 이하의 스크린에선 모바일용 차트 보여줌 */
.mobile_chart {
    display: none;

    @media (max-width: 1250px) {
        display: block;
    }
}

/* rechart CSS */
.recharts-wrapper {
    width : 100px;
}

.recharts-responsive-container {
    height: 280px;
}