@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

* {
  font-family: 'Pretendard-Regular', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--col-black);
  letter-spacing: -0.025rem;
  scroll-behavior: smooth;

  -webkit-font-family: 'Pretendard-Regular';
}

/* media query로 desktop, mobile layout 구분 */
.desktopLayout {
  display: none;
}

.mobileLayout {
  display: none;
}

@media (max-width: 975px) {
  .mobileLayout {
    display: block;
  }
}

@media (min-width: 976px) {
  .desktopLayout {
    display: block;
  }
}