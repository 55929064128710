#UserDetail {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin-bottom: 20px;
    padding: 20px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    box-shadow: 0px 4px 12px var(--col-gray-1);
    transition: box-shadow 0.3s;
}

#UserDetail:hover {
    box-shadow: 0px 8px 14px var(--col-gray-1);
}

#UserDetail .compHeader {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}



#UserDetail .compHeader .title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
}

#UserDetail .compBody {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}


#UserDetail .table {
    width: 100%;
}

#UserDetail .table th {
    padding-bottom: 8px;
    text-align: center;
    font-size: 14px;
    color: var(--col-gray-4);
}

#UserDetail .table tbody {
    border-radius: 8px;
    overflow: hidden;
}

#UserDetail .table .item {
    padding: 8px 0;
}

#UserDetail .table .item:nth-child(2n - 1) {
    background: var(--col-bg);
}

#UserDetail .table .item:hover {
    background: var(--col-gray-1);
}

#UserDetail .table .item td {
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    text-overflow: ellipsis;
}
#UserDetail .table .item td:last-child {
width :23%;
}

#UserDetail .table .item .id {
    text-align: left;
}

#UserDetail .noData {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 160px;
    background: var(--col-bg);
    border-radius: 8px;
    color: var(--col-gray-4);
    font-size: 16px;
}

#UserDetail .noData svg {
    fill: var(--col-primary-1);
}

#UserDetail .tdBtn {
    margin-right: 4px;
    width: 30%;
    padding: 4px 8px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-2);
    border-radius: 4px;
    color: var(--col-gray-4);
    font-size: 13px;
    cursor: pointer;
}

#UserDetail .tdBtn:hover {
    border: 1px solid var(--col-gray-4);
    color: var(--col-black);
}

#UserDetail .tdBtn:last-child {
    margin-right: 0;
}

#script {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(.75px);
}

#script .content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 32px;
    width: 640px;
    background: var(--col-white);
    border-radius: 12px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
}

#script .content .title {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 28px;
}

#script .content .info {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center;
    gap: 4px;
    color: var(--col-danger);
    font-weight: 700;
    font-size: 14px;
}

#script .content .info svg {
    fill: var(--col-danger);
}

@font-face {
    font-family: 'intelone';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2307-1@1.1/intelone-mono-font-family-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

#script .content textarea {
    margin-bottom: 16px;
    padding: 12px;
    width: calc(100% - 24px);
    background: var(--col-gray-1);
    border: 1px solid var(--col-gray-2);
    border-radius: 8px;
    outline: none;
    color: var(--col-black);
    font-family: "intelone";
    overflow: hidden;
    resize: none;
}

#script .func {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 4px;
}


#script .func button {
    padding: 8px 12px;
    background: var(--col-primary-1);
    border: 1px solid var(--col-primary-2);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    color: var(--col-white);
}

#script .func button:hover {
    background: var(--col-primary-3);
    color: var(--col-white);
}

#addSite {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(.75px);
}

#addSite .content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 12px 36px 36px 36px;
    width: 500px;
    background: var(--col-white);
    border-radius: 12px;
}

#addSite .content .title_container .tdPopupBtn {
    margin-right: 30px;
    width: 25%;
    height: 50px;
    padding: 4px 8px;
    background: var(--col-info);
    border: 1px solid var(--col-gray-2);
    border-radius: 4px;
    color: var(--col-white);
    font-weight: 400;
    font-size: 13px;
    cursor: pointer;
}
#addSite .content .title_container .tdPopupBtn:hover {
    box-shadow: 0px 2px 12px var(--col-primary-2);
}
#addSite .content .title_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
}
#addSite .compHeader h1 {
    /* width: 50%; */
    font-size: 32px;
    font-weight: 900;
    color: var(--col-black);
}

#addSite .content h3 {
    margin: 0 0 4px 0;
    width: 100%;
    text-align: start;
    font-size: 14px;
    font-weight: 400;
    color: var(--col-gray-4);
}

#addSite .content .inputWrap {
    position: relative;
    margin-bottom: 8px;
}

#delSite .inputWrap {
    position: relative;
}

#addSite .content .inputWrap svg {
    position: absolute;
    top: 13px;
    right: 12px
}

#addSite .error {
    background: rgba(225, 96, 112, .3);
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 12px 16px;
    color: var(--col-danger);
    font-weight: 600;
    font-size: 14px;
}

#delSite {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(.75px);
}

#delSite .content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 12px 36px 36px 36px;
    width: 360px;
    background: var(--col-white);
    border-radius: 12px;
}


#delSite .content h1 {
    width: 100%;
    font-size: 32px;
    font-weight: 900;
    color: var(--col-black);
    margin-bottom: 0;
}

#delSite .content h3 {
    margin: 0 0 4px 0;
    width: 100%;
    text-align: start;
    font-size: 14px;
    font-weight: 400;
    color: var(--col-gray-4);
}

#delSite .content .inputWrap {
    position: relative;
    margin-bottom: 8px;
}

#delSote .inputWrap {
    position: relative;
}

#delSite .content .inputWrap svg {
    position: absolute;
    top: 13px;
    right: 12px
}

#delSite .error {
    background: rgba(225, 96, 112, .3);
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 12px 16px;
    color: var(--col-danger);
    font-weight: 600;
    font-size: 14px;
}

#delSite .fullBtn {
    background: var(--col-danger);
}

#delSite .fullBtn:hover {
    box-shadow: 0px 2px 12px var(--col-danger);
}

#addSite .content .close,
#delSite .content .close,
#script .content .close {
    position: absolute;
    top: 24px;
    right: 24px;
    display: block;
    width: 40px;
    height: 40px;
    fill: var(--col-gray-3);
}

#addSite .content .close:hover,
#delSite .content .close:hover,
#script .content .close:hover {
    fill: var(--col-gray-4);
}

@media (max-width: 1250px) {
    #UserDetail {
        width: 100%;
        padding: 0;
    }

    #UserDetail .compHeader .title {
        padding: 1.5rem;
        padding-bottom: 0.5rem;
    }

    #UserDetail .compBody .table th,
    #UserDetail .compBody .table td {
        white-space: nowrap;
        max-width: 2rem;
        overflow: auto;
        text-overflow: clip;
    }

    #UserDetail .compBody .table td:last-child {
        max-width: none;
    }

    #UserDetail .compBody .table .tdBtn {
        width: 100%;
    }

    #addSite .content {
        width: 80vw;
        max-width: 500px;
        padding: 12px 20px 20px 20px;
    }

    #addSite .content .title_container .tdPopupBtn {
        width: 50%;
        margin: 8px 0;
    }

    #addSite .content .title_container {
        flex-direction: column;
        align-items: stretch;
        margin-bottom: 12px;
    }

    #addSite .content h1 {
        font-size: 2rem;
    }

    #addSite .error {
        font-size: 1.5rem;
        padding: 10px 14px;
    }

    /* script 수정 */
    #script .content {
        padding: 12px 20px 20px 20px;
        width: 80vw;
    }

    #script .content textarea {
        height: 12.5vh;
        overflow: visible;
    }

    /* delSite */
    #delSite .content {
        width: 80vw;
        padding: 12px 20px 20px 20px;
    }

    #delSite .content h1 {
        font-size: 2rem;
    }
}