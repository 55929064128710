/* @font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

* {
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 14px;
    color: var(--col-black);
    letter-spacing: -0.025rem;
    scroll-behavior: smooth;

    -webkit-font-family: 'Pretendard-Regular';
} */

:root {
    --col-white: #FFFFFF;
    --col-gray-1: #EEEEEE;
    --col-gray-2: #C2C8CF;
    --col-gray-3: #8C939C;
    --col-gray-4: #686C73;
    --col-gray-5: #444B53;
    --col-black: #2E353D;
    --col-primary-1: #2392F3;
    --col-primary-2: #2293F3;
    --col-primary-3: #3575E4;
    --col-primary-4: #426BDD;
    --col-primary-5: #4C6CE0;
    --col-primary-6: #E7F1FF;

    /* --col-primary-1: #00946D;
    --col-primary-2: #26AB7A;
    --col-primary-3: #48C091;
    --col-primary-4: #90D7C3;
    --col-primary-5: #028153; */
    --col-success: #2CBF6D;
    --col-warning: #EFC663;
    --col-danger: #E16070;
    --col-danger-light: #e47380;
    --col-info: #2C97DE;
    --col-bg: #F8F8F8;
    --screen-fhd-full: 1920px;
    --screen-fhd-half: 960px;
}

html {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    min-width: 960px;
    min-height: 100vh;
    height: 100%;
}

input {
    cursor: pointer;
}

input:disabled {
    cursor: not-allowed;
}

input[type="radio"] {
    display: none;
}

input[type="radio"]+label {
    margin-right: 12px;
}

input[type="radio"]+label span {
    display: inline-block;
    margin-right: 4px;
    width: 12px;
    height: 12px;
    background: var(--col-gray-1);
    border: 1px solid var(--col-gray-2);
    border-radius: 50%;
}

input[type="radio"]:checked+label span {
    display: inline-block;
    margin-right: 4px;
    width: 6px;
    height: 6px;
    background: var(--col-white);
    border: 4px solid var(--col-primary-1);
    border-radius: 50%;
}

input[type="date"] {
    width: 100%;
    padding: 12px 1px;
    text-align: center;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 8px;
    outline: none;
}

input[type="date"]:hover {
    border: 1px solid var(--col-primary-2);
}

input[type="date"]:focus {
    border: 1px solid var(--col-primary-1);
    box-shadow: 0 0 0 1px var(--col-primary-1) inset;
}

input[type="date"]:disabled {
    width: 100%;
    padding: 12px 1px;
    text-align: start;
    background: var(--col-gray-1);
    border: 1px solid var(--col-gray-1);
    border-radius: 8px;
    color: var(--col-gray-2);
}

.fullBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 8px;
    width: 100%;
    background: var(--col-primary-1);
    border: none;
    outline: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    color: var(--col-white);
    cursor: pointer;
    box-shadow: none;
    transition: box-shadow 0.3s;
}

.fullBtn:hover {
    box-shadow: 0px 2px 12px var(--col-primary-2);
}

.fullBtn:disabled {
    background: var(--col-gray-1);
    box-shadow: none;
    cursor: not-allowed;
}

#up {
    margin-left: 8px;
    fill: var(--col-danger);
    transform: rotate(90deg);
}

#down {
    margin-left: 8px;
    fill: var(--col-info);
    transform: rotate(-90deg);
}

.recharts-surface {
    max-width: 1560px;
}

table {
    border-spacing: 0;
}