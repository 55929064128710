.global-loading-container {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 9999999;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.global-loading-container .loading-spinner {
    font-size: 5rem;
    animation: spin 2s linear infinite;
}


.global-loading-container .loading-text {
    color: #FFFFFF;
    margin-top: 1rem;
    font-size: 1.5rem;
}

@keyframes spin {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}