#inflowByDevice {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin-bottom: 20px;
    padding: 20px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    box-shadow: 0px 4px 12px var(--col-gray-1);
    transition: box-shadow 0.3s;
}

#inflowByDevice:hover {
    box-shadow: 0px 8px 14px var(--col-gray-1);
}

#inflowByDevice .compHeader {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


#inflowByDevice .compHeader .title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
}

#inflowByDevice .compBody {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#inflowByDevice .compBody .graph {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}


#inflowByDevice .compBody .graphInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#inflowByDevice .compBody .graphInfo .item {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
}

@media (max-width: 1600px) {
    #inflowByDevice .compBody .graphInfo .item {
        width: 100%;
    }
}

#inflowByDevice .compBody .graphInfo .item span {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

#inflowByDevice .compBody .graphInfo .item .channel {
    margin: 0 8px;
    color: var(--col-gray-4);
}


#inflowByDevice .compBody .graphInfo .item .percent {
    flex: 1;
    text-align: end;
    color: var(--col-gray-4);
}