@font-face {
    font-family: 'intelone-mono-font-family-regular';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2307-1@1.1/intelone-mono-font-family-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

#RatePlan {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin-bottom: 20px;
    padding: 20px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    box-shadow: 0px 4px 12px var(--col-gray-1);
    transition: box-shadow 0.3s;
}
#RatePlan .compHeader {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


#RatePlan .compHeader .title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
}

#RatePlan .compBody {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}
#RatePlan .compBody .etc{
font-size: 30px;
text-align: center;
color: #8C939C;
}

