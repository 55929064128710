#notFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100vw;
    min-height: 100vh;
    background: var(--col-bg);
}

#notFound svg {
    fill: var(--col-primary-1);
}

#notFound .fullBtn {
    width: 420px;
}

#notFound h1 {
    margin: 40px 0 12px 0;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -4px;
    color: var(--col-primary-1);
}

#notFound h1 strong {
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -4px;
    color: var(--col-black);
}

#notFound .info {
    margin-bottom: 20px;
}

#notFound .info p {
    margin: 8px;
    padding: 0;
    font-size: 14px;
    color: var(--col-gray-4);
}