#popularPage {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin-bottom: 20px;
    padding: 20px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    box-shadow: 0px 4px 12px var(--col-gray-1);
    transition: box-shadow 0.3s;
}

#popularPage:hover {
    box-shadow: 0px 8px 14px var(--col-gray-1);
}

#popularPage .compHeader {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


#popularPage .compHeader .title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
}

#popularPage .compBody {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#popularPage .rank {
    width: 100%;
}

#popularPage .rank th {
    padding-bottom: 8px;
    text-align: center;
    font-size: 14px;
    color: var(--col-gray-4);
    
}

#popularPage .rank tbody {
    border-radius: 8px;
    overflow: hidden;
    
}

#popularPage .rank .item {
    padding: 8px 0;
    
}

#popularPage .rank .item:nth-child(2n - 1) {
    background: var(--col-bg);
    
}

#popularPage .rank .item:hover {
    background: var(--col-gray-1);
}

#popularPage .rank .item td:nth-child(n+3) {
    text-align: center;
}

#popularPage .rank .item td {
    padding: 8px 0;
}

#popularPage .rank .item td:nth-child(n + 2) {
    /* 최대 표시 길이를 조절하세요. */
    white-space: nowrap;
    overflow: hidden;
}

#popularPage .rank .item td:nth-child(n + 2):hover {
    overflow: visible;
    overflow-x: auto;
    white-space: nowrap;
}

#popularPage .rank .item td a {
    margin: 0 8px;
}

#popularPage .rank .item .no {
    width: 32px;
    text-align: center;
}

#popularPage .rank .item .keyword {
    flex: 1;
    font-size: 16px;
    color: var(--col-gray-4);
}

#popularPage .rank .item .percent {
    font-size: 16px;
    color: var(--col-gray-4);
}

@media (max-width: 768px) {
    #popularPage {
        width: 100%;
        padding: 0;
    }

    #popularPage .compHeader {
        padding: 1.5rem;
        padding-bottom: 0.5rem;
    }

    #popularPage .rank th {
        white-space: nowrap;
    }

    #popularPage .rank .no  {
        display: none;
    }

    #popularPage .rank .item td {
        padding: 8px 12px;
        max-width: 240px;
    }   
}