#maDetail {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin-bottom: 20px;
    padding: 20px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    box-shadow: 0px 4px 12px var(--col-gray-1);
    transition: box-shadow 0.3s;
}

#maDetail:hover {
    box-shadow: 0px 8px 14px var(--col-gray-1);
}

#maDetail .compHeader {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


#maDetail .compHeader .title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
}

#maDetail .compBody {
    display: flex;
    flex-direction: column;
    width: 100%;
}