#dtDetail {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin-bottom: 20px;
    padding: 20px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    box-shadow: 0px 4px 12px var(--col-gray-1);
    transition: box-shadow 0.3s;
}

#dtDetail:hover {
    box-shadow: 0px 8px 14px var(--col-gray-1);
}

#dtDetail .compHeader {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


#dtDetail .compHeader .title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
}

#dtDetail .compBody {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#dtDetail .compBody .summary {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#dtDetail .compBody .summary .item {
    margin-right: 20px;
    margin-bottom: 16px;
    font-size: 14px;
    color: var(--col-gray-4);
}

#dtDetail .compBody .summary .item strong {
    margin: 0 2px 0 4px;
    font-size: 15px;
    font-weight: 700;
    color: var(--col-primary-4);
}

#dtDetail .table {
    width: 100%;
}

#dtDetail .table th {
    padding-bottom: 8px;
    text-align: center;
    font-size: 14px;
    color: var(--col-gray-4);
}

#dtDetail .table tbody {
    border-radius: 8px;
    overflow: hidden;
}

#dtDetail .table .item {
    padding: 8px 0;
}

#dtDetail .table .item:nth-child(2n - 1) {
    background: var(--col-bg);
}

#dtDetail .table .item:hover {
    background: var(--col-gray-1);
}

#dtDetail .table .item td {
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    text-align: end;

}

#dtDetail .table .item .date {
    text-align: center;
}

#dtDetail .table .item .ratio {
    display: flex;
    flex-direction: row;
}

#dtDetail .table .item .ratio .barWrap {
    display: block;
    margin-right: 8px;
    width: 90%;
    height: 12px;
    background: var(--col-gray-1);
    border: 1px solid var(--col-bg);
}

@media (max-width: 1200px) {
    #dtDetail .table .item .ratio .barWrap {
        display: block;
        margin-right: 8px;
        width: 85%;
        height: 16px;
        background: var(--col-gray-1);
        border: 1px solid var(--col-bg);
    }
}

#dtDetail .table .item .ratio .barWrap .bar {
    display: block;
    height: 12px;
    background: var(--col-primary-3);
}