#osDetail {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin-bottom: 20px;
    padding: 20px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    box-shadow: 0px 4px 12px var(--col-gray-1);
    transition: box-shadow 0.3s;
}

#osDetail:hover {
    box-shadow: 0px 8px 14px var(--col-gray-1);
}

#osDetail .compHeader .func {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

#osDetail .compHeader .func .info {
    flex: 1;
    font-size: 14px;
    color: var(--col-gray-4);
}

#osDetail .compHeader .func .info strong {
    margin-left: 8px;

    font-weight: 700;
    color: var(--col-primary-5);
}

#osDetail .compHeader .func .rowNum {
    padding: 2px 4px;
    border-radius: 4px;
    border: 1px solid var(--col-gray-2);
    outline: none;
    color: var(--col-black);
}
#osDetail .compHeader {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    /* flex-direction: row;
    justify-content: space-between;
    align-items: center; */
}


#osDetail .compHeader .title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
}

#osDetail .compBody {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#osDetail .table {
    width: 100%;
}

#osDetail .table th {
    padding-bottom: 8px;
    text-align: center;
    font-size: 14px;
    color: var(--col-gray-4);
}

#osDetail .table tbody {
    border-radius: 8px;
    overflow: hidden;
}

#osDetail .table .item {
    padding: 8px 0;
}

#osDetail .table .item:nth-child(2n - 1) {
    background: var(--col-bg);
}

#osDetail .table .item:hover {
    background: var(--col-gray-1);
}

#osDetail .table .item td {
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

#osDetail .table .item .rank {
    width: 32px;
    text-align: center;
}

#osDetail .table .item .os {
    text-align: left;
}
#osDetail .compFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-top: 20px;
    width: 100%;
}

#osDetail .compFooter .pageBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-2);
    border-radius: 4px;
    color: var(--col-gray-4);
}

#osDetail .compFooter .pageBtn svg {
    fill: var(--col-gray-4);
}