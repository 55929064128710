#sign {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: var(--col-bg);
    
}

#sign .signBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    padding: 16px 20px;
    width: 100%;
    background: var(--col-primary-2);
    border: none;
    outline: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    color: var(--col-white);
    cursor: pointer;
    box-shadow: none;
    transition: box-shadow 0.3s;
}

#sign .signBtn:hover {
    box-shadow: 0px 2px 12px var(--col-primary-4);
}

#sign .signBtn:disabled,
#sign .signBtn:disabled:hover {
    background: var(--col-gray-1);
    box-shadow: none;
    cursor: not-allowed;
}

#sign .link {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
    width: 100%;
    color: var(--col-gray-3);
}

#sign .link a {
    color: var(--col-primary-1);
    font-weight: 600;
}

#sign .error {
    background: rgba(225, 96, 112, .3);
    border-radius: 8px;
    padding: 12px 16px;
    color: var(--col-danger);
    font-weight: 600;
    font-size: 14px;
    text-align: center;
}
#sign .success {
    background: var(--col-primary-1);
    border-radius: 8px;
    padding: 12px 16px;
    color: var(--col-white);
    font-weight: 600;
    font-size: 14px;
    text-align: center;
}

input {
    margin-bottom: 8px;
    padding: 12px 16px;
    width: calc(100% - 32px);
    background: var(--col-white);
    border: 1px solid var(--col-gray-2);
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    transition: border .3s, box-shadow .3s;
}

input:hover {
    border: 1px solid var(--col-primary-2);
}

input:focus {
    border: 1px solid var(--col-primary-1);
    box-shadow: 0 0 0 1px var(--col-primary-1) inset;
}

#sign .inputGuide {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    margin-bottom: 20px;
}

#sign .inputGuide .guide {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--col-gray-3);
    margin: 2px;
}

#sign .inputGuide .guide svg {
    margin-right: 8px;
}

#sign .home {
    position: fixed;
    top: 40px;
    left: 40px;
}

#sign .home a {
    font-size: 24px;
    text-decoration: none;
}

#sign .close {
    position: absolute;
    top: 32px;
    right: 32px;
    fill: var(--col-gray-2)
}

#sign .close:hover {
    fill: var(--col-black)
}

#signin,
#signup {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 12px 36px 36px 36px;
    width: 360px;
    background: var(--col-white);
    border-radius: 12px;
    box-shadow: 0px 0px 12px var(--col-gray-1);
}

#signin h1,
#signup h1 {
    width: 100%;
    text-align: center;
    font-size: 40px;
    font-weight: 900;
    color: var(--col-black);
}

#signin h3,
#signup h3 {
    margin: 0 0 4px 0;
    width: 100%;
    text-align: start;
    font-size: 14px;
    font-weight: 400;
    color: var(--col-gray-4);
}

#signin .inputWrap {
    position: relative;
    margin-bottom: 8px;
}

#signup .inputWrap {
    position: relative;
}
#signup .signinputWrap {
    display: flex;
    /* position: relative; */
}
#signup .signinputWrap input[type="button"] {
    /* color: blue; */
    margin-left: 10px;
    max-width: 90px;
}

#signin .inputWrap svg,
#signup .inputWrap svg {
    position: absolute;
    top: 13px;
    right: 12px
}
