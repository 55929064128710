.mobile_header_container {
    display: none;
    padding: 0 32px;

    @media (max-width: 975px) {
        padding: 0 1rem;
    }
}

.mobile_header_container #mobile_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: auto;
}

#mobile_header .leftSide .pageTitle {
    font-size: 28px;
    font-weight: 700;
    color: var(--col-black);
}

#mobile_header .rightSide {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#mobile_header .rightSide .selWrap {
    position: relative;
}

#mobile_header .rightSide .selWrap svg {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translate(-50%, -75%);
}

#mobile_header .rightSide #selSite {
    width: 100%;
    min-width: 320px;
    padding: 12.5px 20px 12.5px 8px;
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    outline: none;
    appearance: none;
    cursor: pointer;
}

#mobile_header .rightSide .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    width: 42px;
    height: 42px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    cursor: pointer;
}

#mobile_header .rightSide .btn svg {
    fill: var(--col-gray-4);
}

#mobile_header .rightSide .btn:hover svg {
    fill: var(--col-primary-1);
}

#mobile_header .pageViewSide {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    width: 100%;
}

#mobile_header .PageViewTitle {
    width: 100%;
    font-size: 17px;
    text-align: right;
    margin-right: 15px;
}

#mobile_header .pageViewSide .fullBtn {
    width: 155px;
    font-size: 13px;
    padding: 5px;
    font-weight: 600;
    text-decoration: none
}

/* Mobile */
@media (max-width: 1250px) {
    .mobile_header_container {
        display: block;
    }

    #mobile_header {
        width: 100%;
        height: auto;

        display: flex;
        align-items: normal;
        flex-direction: column;
        margin-top: 4.5rem;
    }

    #mobile_header .rightSide {
        width: 100%;
        flex-direction: column;
        align-items: stretch;

        gap: 1rem;
    }

    #mobile_header .rightSide #selSite {
        width: 100%;
    }

    #mobile_header .rightSide .btn_box {
        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 1rem;
    }

    #mobile_header .rightSide .fullBtn {
        width: 100%;
        height: 40px;

        padding: 0;

        font-size: 1rem;
    }

    #mobile_header .rightSide .btn {
        width: 100%;
        height: 40px;

        margin: 0;
    }

    #mobile_header .leftSide {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        margin: 2rem 0 1rem 0;
    }

    #mobile_header .leftSide .pageTitle {
        font-size: 1.75rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    #mobile_header .PageViewTitle {
        width: auto;
        font-size: 1.25rem;
        margin-right: 0;
    }
}