#isSummary {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin-bottom: 20px;
    padding: 20px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    box-shadow: 0px 4px 12px var(--col-gray-1);
    transition: box-shadow 0.3s;
}

#isSummary:hover {
    box-shadow: 0px 8px 14px var(--col-gray-1);
}

#isSummary .compHeader {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

#isSummary .compHeader .title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
}

#isSummary .compHeader .info {
    margin-bottom: 16px;
    font-size: 16px;
    color: var(--col-gray-3);
}

#isSummary .compBody {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 16px;
}

#isSummary .compBody .item {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 12px 20px;
    background: var(--col-white);
    min-width: 112px;
    border: 1px solid var(--col-gray-1);
    border-radius: 8px;
    overflow: hidden;
}

#isSummary .compBody .item:last-child {
    margin-right: 0;
}

@media (max-width: 1200px) {
    #isSummary .compBody {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        gap: 16px;
    }

    #isSummary .compBody .item {
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
        padding: 12px 20px;
        width: calc(100% - 40px);
        background: var(--col-white);
        border: 1px solid var(--col-gray-1);
        border-radius: 12px;
        overflow: hidden;
    }
}

#isSummary .compBody .item .itemHeader {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 8px;
    height: 88px;
    border-bottom: 1px solid var(--col-gray-1);
}

#isSummary .compBody .item .itemHeader .title {
    margin-right: 12px;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 900;
    color: var(--col-gray-4);
}

#isSummary .compBody .item .itemHeader .value {
    font-size: 32px;
    font-weight: 700;
    color: var(--col-black);
}

#isSummary .compBody .item .itemHeader .value1 {
    font-size: 24px;
    font-weight: 700;
    color: var(--col-black);
}

#isSummary .compBody .item .itemHeader .channel {
    font-size: 24px;
    font-weight: 700;
    color: var(--col-black);
}

#isSummary .compBody .item .itemBody {
    display: flex;
    flex-direction: column;
    margin: 0;
}

#isSummary .compBody .item .itemBody .info {
    font-size: 14px;
    font-weight: 400;
    color: var(--col-gray-3);
}