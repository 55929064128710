#overview {
    display: flex;
    flex-direction: row;
}

#overview .c1 {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    min-width: 320px;
    max-width: 360px;
    width: 20%;
    height: 100%;
}

#overview .c2 {
    flex: 1;
}

#overview .c2-1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

#overview .c2-1-1 {
    flex: 2;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

#overview .c2-1-2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

#overview .c2-1-2>div {
    flex: 1;
}

#manage {
    display: flex;
    flex-direction: column;
    padding: 32px;
}

@media (max-width: 1250px) {
    
    #overview {
        display: flex;
        flex-direction: column;
    }

    #overview .c1 {
        margin-right: 0;
        max-width: 100%;
        margin-bottom: 20px;
        width: 100%;
    }

    #overview .c2 {
        flex: 1;
    }

    #overview .c2-1 {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    #manage {
        padding: 0;
        justify-content: center;
        align-items: center;
    }
}