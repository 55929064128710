.m_view {
    display: none;
}


#Email {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin-bottom: 20px;
    padding: 20px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    box-shadow: 0px 4px 12px var(--col-gray-1);
    transition: box-shadow 0.3s;
}

#Email:hover {
    box-shadow: 0px 8px 14px var(--col-gray-1);
}

#Email .compHeader {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}


#Email .compHeader .title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
}

#Email .compBody {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#Email .rank {
    width: 100%;
}

#Email .rank th {
    padding-bottom: 8px;
    text-align: center;
    font-size: 14px;
    color: var(--col-gray-4);
}

#Email .rank th {
    width: 5%;
    max-width: 580px;
}

#Email .rank tbody {
    border-radius: 8px;
    overflow: hidden;
}

#Email .rank .item:nth-child(2n - 1) {
    background: var(--col-bg);
}

#Email .rank .item:hover {
    background: var(--col-gray-1);
}

#Email .rank .item td {
    padding: 20px 12px;
    text-align: center;
}

#Email .rank .item td.logData div p:first-child {
    font-weight: 700;
}

#Email .rank .item td.logData div:last-child {
    border-bottom: 0px;
}

#Email .rank .item td a:hover {
    text-decoration: underline;
}

#Email .rank .item .no {
    width: 32px;
    text-align: center;
}

#Email .fullBtn {
    padding: 8px 12px;
    background: var(--col-primary-1);
    border: 1px solid var(--col-primary-2);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    color: var(--col-white);
}

#Email .fullBtn:hover {
    background: var(--col-primary-3);
    color: var(--col-white);
}

@media (max-width: 1200px) {
    .m_view {
        display: block;
    }
}

@media (max-width: 1500px) {
    .m_view {
        display: block;
    }
}

/* Mobile */
@media (max-width: 1200px) {
    #Email {
        width: 100%;
        padding: 0;
    }

    #Email .compHeader {
        padding: 1.5rem;
        padding-bottom: 0.5rem;
    }

    #Email .compBody {
        align-items: center;
    }

    #Email .rank {
        margin-bottom: 2.5rem;
    }

    #Email .rank th {
        width: auto;
        white-space: nowrap;
    }
    #Email .rank .no {
        display: none;
    }
    #Email .rank .item td {
        word-break: break-all;
        white-space: normal;
        flex: 2;
    }
    
    #Email .rank .item td.btn {
        padding: 0;
    }
    #Email .rank .item td.btn button {
        white-space: nowrap;
    }

    #Email .fullBtn {
        width: 90%;
        margin-bottom: 1.5rem;
    }
}