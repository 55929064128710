#uvGraph {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin-bottom: 20px;
    padding: 20px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    box-shadow: 0px 4px 12px var(--col-gray-1);
    transition: box-shadow 0.3s;
}

#uvGraph:hover {
    box-shadow: 0px 8px 14px var(--col-gray-1);
}

#uvGraph .compHeader {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#uvGraph .compHeader .title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
}

#uvGraph .compBody {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#uvGraph .compBody .graph {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: calc(100% - 40px);
    min-height: 200px;
    height: 200px;
    font-weight: 700;
    margin-bottom: 16px;
}

#uvGraph .compBody .graph .chart {
    width: 100%;
    max-width: 1160px;
    height: 100%;
}

.recharts-responsive-container {
    height: 200px;
}

.recharts-surface {
    width: 100%;
    height: 100%;
}

#uvGraph .compBody .graphInfo {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 16px;
    font-size: 14px;
}

#uvGraph .compBody .graphInfo .legendInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 12px;
    color: var(--col-gray-3);
}

#uvGraph .compBody .graphInfo .legendInfo span {
    display: inline-block;
    margin-right: 4px;
    width: 12px;
    height: 12px;
    background: var(--col-gray-3);
    border-radius: 50%;
}

#uvGraph .compBody .graphInfo .totalNum span {
    background: var(--col-success);
}

#uvGraph .compBody .graphInfo .totalCnt span {
    background: var(--col-info);
}

#uvGraph .compBody .graphInfo .newVisitNum span {
    background: var(--col-danger);
}

#uvGraph .compBody .graphInfo .reVisitNum span {
    background: var(--col-warning);
}

#uvGraph .compBody .graphInfo .pageView span {
    background: var(--col-gray-3);
}

@media (max-width: 1200px) {
    #uvGraph .compHeader {
        display: none;
    }

    #uvGraph .compBody .graph {
        display: none;
    }

    #uvGraph .compBody .graphInfo {
        display: none;
    }
}