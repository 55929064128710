@media (max-width: 1250px) {
    #sidebar {
        position: fixed;
        top: 0;
        bottom: 0;
        
        overflow: scroll;
        overflow: visible;

        z-index: 1001;

        transition: 0.4s ease;
    }

    #sidebar .sidebar_drawer {
        position: fixed;
        top: 1rem;
        /* right: -2.5rem; */
        right: -2.9rem;

        width: 40px;
        height: 40px;

        background-color: var(--col-info);
        color: white;

        border-radius: 0 10px 10px 0;
        border: none;

        font-size: 1rem;
    }

    #sidebar .menu .step1 {
        list-style-type: none;
        padding-left: 20px;
    }
}