
.l_footer_container {
  background-color:#000000;
  padding: 38.5px 20px 38.5px 33px; 
  margin-top: 0px;
}
.l_footer_top{
  line-height: 26.4px;
}
.l_footer_info.text_pc{
 margin-bottom: 0px;
 font-size: 14px;
 line-height: 16.8px;
 color: #FFFFFF !important;
}
.l_footer_title , .l_footer_title span{
font-size: 22px;
font-weight: 700;
color: #FFFFFF !important;
}

.l_footer_bottom {
display: flex;
justify-content: space-between;
align-items: center;
margin-right: 10px;
color: #FFFFFF !important;

}
.l_footer_bottom p , .l_footer_bottom span{
  width: 399px;
  font-size: 14px;
  margin-bottom: 0px;
  color: #FFFFFF !important;
  }
  .l_modal_content dl dd span {
    color: #4068EE;
}
.l_footer_icon{
  padding-left: 3px;
  margin-bottom: 0px;
}
.l_footer_icon a{
  padding-right: 8px;
  margin-bottom: 0px;
}
 .l_modal_popup {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    font-weight: 800;
    color: #FFFFFF;
}

/* 모달 스타일 */
.l_modal_overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0; 
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 9999;
}

.l_modal{
  width: 500px; 
    height:450px;
    background:#fff;
    border-radius:10px;
    position:relative;
    text-align:left;
    box-sizing:border-box;
    padding: 2%;
    cursor:pointer;
    color: #000;
    overflow-y: scroll;

  }
  .l_modal_content dl {
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    text-align: center;
}

  .l_modal_content dl dt {
    font-size: 1.5rem;
    margin-bottom: 5%;
}
.l_modal_content dl dd span {
    color: #4068EE;
}
.l_modal_content dl dd {
    margin: 0 auto;
    line-height:2rem;
}

.l_m_view_footer {
  display: none
}
  @media screen and (max-width: 1200px) {
/* 
    #m_view {
      display: block
    } */

    }

    @media screen and (max-width: 500px) {
      
    .l_m_view_footer {
      display: block
    }
      .l_footer_title {
        font-size: 1rem;
      }
      .l_footer_title span {
        font-size: 1rem;
      }
      .l_footer_info.text_pc, .modal_popup{
        font-size: 1rem;
        line-height: 30px;
      }
      .l_footer_bottom p ,.l_footer_bottom span{
        font-size: 1rem;
      }
      .l_footer_icon img{
        width : 50px;
      }
      .l_footer_bottom{
       display: block;
      }
      .l_footer_bottom p {
        margin-bottom: 3%;
      }
      .l_modal{
        width: 70%;
        height: 30%;
        overflow-y: scroll;
      }

      .l_modal_content dl dt{
        font-size: 1.5rem;
      }
      .l_modal_content dl dd, .l_modal_content dl dd span{
        font-size: 1rem;
        line-height: 1.4rem;
        margin: 0;
      }
      }