#dropDown {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 24px;
    top: 76px;
    padding: 12px 0;
    width: 160px;
    background: var(--col-white);
    border-radius: 12px;
    z-index: 1000;
    box-shadow: 0px 2px 12px var(--col-gray-1);
}

#dropDown .item {
    padding: 8px 0px;
    color: var(--col-gray-4);
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}

#dropDown .item:hover {
    color: var(--col-black);
}

#dropDown .signOut {
    padding: 8px 0px;
    color: var(--col-danger-light);
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

#dropDown .signOut:hover {
    color: var(--col-danger);
}