#inflowKeyword {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin-bottom: 20px;
    padding: 20px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    box-shadow: 0px 4px 12px var(--col-gray-1);
    transition: box-shadow 0.3s;
}

#inflowKeyword:hover {
    box-shadow: 0px 8px 14px var(--col-gray-1);
}

#inflowKeyword .compHeader {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


#inflowKeyword .compHeader .title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
}

#inflowKeyword .compBody {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#inflowKeyword .rank {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

#inflowKeyword .rank .item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#inflowKeyword .rank .item .no {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border: 1px solid var(--col-gray-1);
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    color: var(--col-gray-4);
}

#inflowKeyword .rank .item .keyword {
    flex: 1;
    font-size: 16px;
    color: var(--col-gray-4);
}

#inflowKeyword .rank .item .percent {
    font-size: 16px;
    color: var(--col-gray-4);
}