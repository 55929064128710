/* 중앙 정렬을 위해 한번 더 감쌈 */
.header_container {
    display: block;
}

#header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 32px;
    width: calc(100% - 64px);
    height: 92px;
}

#header .leftSide .pageTitle {
    white-space: nowrap;
    font-size: 28px;
    font-weight: 700;
    color: var(--col-black);
}

#header .rightSide {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#header .rightSide .selWrap {
    position: relative;
}

#header .rightSide .selWrap svg {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translate(-50%, -75%);
}

#header .rightSide #selSite {
    min-width: 320px;
    padding: 12.5px 20px 12.5px 8px;
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    outline: none;
    appearance: none;
    cursor: pointer;
}

#header .rightSide .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    width: 42px;
    height: 42px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    cursor: pointer;
}

#header .rightSide .btn svg {
    fill: var(--col-gray-4);
}

#header .rightSide .btn:hover svg {
    fill: var(--col-primary-1);
}

#header .pageViewSide {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    width: 100%;
}

#header .PageViewTitle {
    width: 100%;
    font-size: 17px;
    text-align: right;
    margin-right: 15px;
}

#header .pageViewSide .fullBtn {
    width: 155px;
    font-size: 13px;
    padding: 5px;
    font-weight: 600;
    text-decoration: none
}