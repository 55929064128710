#spDetail {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin-bottom: 20px;
    padding: 20px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    box-shadow: 0px 4px 12px var(--col-gray-1);
    transition: box-shadow 0.3s;
}

#spDetail:hover {
    box-shadow: 0px 8px 14px var(--col-gray-1);
}

#spDetail .compHeader {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}


#spDetail .compHeader .title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
}

#spDetail .compHeader .func {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

#spDetail .compHeader .func .info {
    flex: 1;
    font-size: 14px;
    color: var(--col-gray-4);
}

#spDetail .compHeader .func .info strong {
    margin-left: 8px;

    font-weight: 700;
    color: var(--col-primary-5);
}

#spDetail .compHeader .func .rowNum {
    padding: 2px 4px;
    border-radius: 4px;
    border: 1px solid var(--col-gray-2);
    outline: none;
    color: var(--col-black);
}

#spDetail .compBody {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#spDetail .rank {
    width: 100%;
}

#spDetail .rank th {
    padding-bottom: 8px;
    text-align: center;
    font-size: 14px;
    color: var(--col-gray-4);
}

#spDetail .rank tbody {
    border-radius: 8px;
    overflow: hidden;
}

#spDetail .rank .item {
    padding: 8px 0;
}

#spDetail .rank .item:nth-child(2n - 1) {
    background: var(--col-bg);
}

#spDetail .rank .item:hover {
    background: var(--col-gray-1);
}

#spDetail .rank .item td {
    padding: 8px 0;
}

#spDetail .rank .item td:nth-child(2) {
    overflow: hidden;
    white-space: nowrap;
    /* text-overflow: ellipsis; */
    max-width: 350px;
    /* 최대 표시 길이를 조절하세요. */
}

#spDetail .rank .item td:nth-child(2):hover {
    overflow-x: scroll;
    /* 호버 상태일 때 스크롤을 표시 */
}

#spDetail .rank .item td:nth-child(n + 3) {
    text-align: center;
}

#spDetail .rank .item td a {
    margin: 0 8px;
    text-decoration: none;
}

#spDetail .rank .item td a:hover {
    text-decoration: underline;
}

#spDetail .rank .item .no {
    width: 32px;
    text-align: center;
}

#spDetail .rank .item .ratio {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

#spDetail .rank .item .ratio .barWrap {
    flex: 1;
    display: inline-block;
    margin: 0 8px;
    width: 200px;
    height: 12px;
    background: var(--col-gray-1);
    border: 1px solid var(--col-bg);
}

#spDetail .rank .item .ratio .barWrap .bar {
    display: block;
    height: 12px;
    background: var(--col-primary-3);
}

#spDetail .compFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-top: 20px;
    margin-bottom: 12px;
    width: 100%;
}

#spDetail .compFooter .pageBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-2);
    border-radius: 4px;
    color: var(--col-gray-4);
}

#spDetail .compFooter .pageBtn svg {
    fill: var(--col-gray-4);
}

/* @media (max-width: 1200px) {
    #spDetail .rank .item td:nth-child(3) {
        padding-left: 10px;
        text-overflow: inherit;
    }
} */

/* Mobile */
@media (max-width: 1200px) {
    #spDetail {
        width: 100%;
        padding: 0;
    }

    #spDetail .compHeader {
        padding: 1.5rem;
        padding-bottom: 0.5rem
    }

    #spDetail .compBody .rank th {
        white-space: nowrap;
    }

    #spDetail .compBody .rank .item td {
        padding: 8px 12px;
    }

    #spDetail .compBody .rank .item td:nth-child(2) {
        width: 100%;
        max-width: 50px;
    }

    #spDetail .compBody .rank .item td:nth-child(2):hover {
        overflow-x: auto;
        /* 호버시 스크롤 없애기 */
    }

    #spDetail .compBody .rank .item .ratio .barWrap {
        display: none;
    }

    #spDetail .compBody .rank .item .ratio .value {
        width: 100%;
        text-align: center;
    }
}