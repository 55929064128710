footer {
    background-color: #E7E7E7;
    /* min-height: 216px; */
    height: auto;
}

.mobile_footer_container {
    display: none;
    padding: 38.5px 20px 38.5px 33px;
    margin-top: 0px;
}

.mobile_footer_top {
    line-height: 26.4px;
}

.mobile_footer_info.text_pc {
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 16.8px;
    color: #7A7E83;
}

.mobile_footer_title,
.mobile_footer_title span {
    font-size: 22px;
    font-weight: 700;
    color: #686C73;
}

.mobile_footer_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    color: #7A7E83;
}

.mobile_footer_bottom p,
.mobile_footer_bottom span {
    width: 399px;
    font-size: 14px;
    margin-bottom: 0px;
    color: #7A7E83;
}

.mobile_footer_icon {
    padding-left: 3px;
    margin-bottom: 0px;
}

.mobile_footer_icon:first-child {
    padding-left: 0;
}

.mobile_footer_icon a {
    padding-right: 8px;
    margin-bottom: 0px;
}

.modal_popup {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    color: #7A7E83;
}

/* 모달 스타일 */
.modal_overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.modal {
    width: 500px;
    height: 450px;
    background: #fff;
    border-radius: 10px;
    position: relative;
    text-align: left;
    box-sizing: border-box;
    padding: 2%;
    cursor: pointer;
    color: #000;
}

.modal_content dl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.modal_content dl dt {
    font-size: 2rem;
    margin-bottom: 5%;
}

.modal_content dl dd span {
    color: #4068EE;
}

.modal_content dl dd {
    margin: 0 auto;
    line-height: 2rem;
}

.m_view_mobile_footer {
    display: none
}



/* Mobile */
@media (max-width: 1250px) {
    .mobile_footer_container {
        padding: 0 1rem 1rem 1rem;
        display: block;
    }

    .m_view_mobile_footer {
        display: block
    }

    .mobile_footer_top {
        padding-top: 0.5rem;
    }

    .mobile_footer_title,
    .mobile_footer_title span {
        font-size: 1.5rem;
    }

    .mobile_footer_info.text_pc {
        font-size: 1rem;
        line-height: 1.25rem;
    }

    .modal_popup {
        font-size: 1rem !important;
    }

    .modal {
        width: 80%;
        height: 80%;
        overflow-y: scroll;
    }

    .modal_content dl {
        gap: 1.5rem;
    }

    .modal_content dl dt {
        font-size: 1.5rem;
    }

    .modal_content dl dd {
        font-size: 1rem;
        line-height: 2rem;
    }

    .modal_content dl dd span {
        font-size: 1rem;
    }

    .mobile_footer_bottom {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        margin-bottom: 1rem;
    }

    .mobile_footer_bottom p,
    .mobile_footer_bottom span {
        width: 100%;
        font-size: 1rem !important;
    }

    .mobile_footer_icon img {
        width: 50px
    }
}