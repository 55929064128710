#pvDetail {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin-bottom: 20px;
    padding: 20px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    box-shadow: 0px 4px 12px var(--col-gray-1);
    transition: box-shadow 0.3s;
}

#pvDetail:hover {
    box-shadow: 0px 8px 14px var(--col-gray-1);
}

#pvDetail .compHeader {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


#pvDetail .compHeader .title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
}

#pvDetail .compBody {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#pvDetail .table {
    width: 100%;
}

#pvDetail .table th {
    padding-bottom: 8px;
    text-align: center;
    font-size: 14px;
    color: var(--col-gray-4);
}

#pvDetail .table tbody {
    border-radius: 8px;
    overflow: hidden;
}

#pvDetail .table .item {
    padding: 8px 0;
}

#pvDetail .table .item:nth-child(2n - 1) {
    background: var(--col-bg);
}

#pvDetail .table .item:hover {
    background: var(--col-gray-1);
}

#pvDetail .table .item td {
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;

}

#pvDetail .table .item .date {
    text-align: center;
}