.mobile_layout_container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
    background: var(--col-bg);
}

.mobile_layout_container .mobile_layout_header {
    grid-column: 1 / -1;
}

.mobile_layout_container .mobile_layout_content {
    background-color: var(--col-white);
    padding: 0 0.75rem 0.75rem 0.75rem;
    background: var(--col-bg);
}

.mobile_layout_container .mobile_layout_footer {
    grid-column: 1 / -1;
    color: white;
}