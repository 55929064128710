#mobilePeriodPicker {
    position: static;
    top: 20px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    padding: 20px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    box-shadow: 0px 4px 12px var(--col-gray-1);
    transition: box-shadow 0.3s;
    margin-bottom: 20px;
}

#mobilePeriodPicker:hover {
    box-shadow: 0px 8px 14px var(--col-gray-1);
}

#mobilePeriodPicker .title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
}

#mobilePeriodPicker .option {
    margin-bottom: 16px;
}

#mobilePeriodPicker .preiod {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;
}

#mobilePeriodPicker .preiod input {
    font-size: 15px;
}

#mobilePeriodPicker .preiod span {
    margin: 0 8px;
}