.m_view {
    display: none;
}


#Powerlink {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin-bottom: 20px;
    padding: 20px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-1);
    border-radius: 12px;
    box-shadow: 0px 4px 12px var(--col-gray-1);
    transition: box-shadow 0.3s;
}

#Powerlink:hover {
    box-shadow: 0px 8px 14px var(--col-gray-1);
}

#Powerlink .compHeader {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}


#Powerlink .compHeader .title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
}

#Powerlink .compHeader .func {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

#Powerlink .compHeader .func .info {
    flex: 1;
    font-size: 14px;
    color: var(--col-gray-4);
}

#Powerlink .compHeader .func .info strong {
    margin-left: 8px;

    font-weight: 700;
    color: var(--col-primary-5);
}

#Powerlink .compHeader .func .rowNum {
    padding: 2px 4px;
    border-radius: 4px;
    border: 1px solid var(--col-gray-2);
    outline: none;
    color: var(--col-black);
}

#Powerlink .compBody {
    display: flex;
    flex-direction: column;
    width: 100%;
}

#Powerlink .rank {
    width: 100%;
}

#Powerlink .rank th {
    padding-bottom: 8px;
    text-align: center;
    font-size: 14px;
    color: var(--col-gray-4);
}

#Powerlink .rank th:last-child {
    min-width: 250px;
    width: 5%;
    max-width: 580px;
}

#Powerlink .rank tbody {
    border-radius: 8px;
    overflow: hidden;
}

#Powerlink .rank .item {
    padding: 8px 0;
}

#Powerlink .rank .item:nth-child(2n - 1) {
    background: var(--col-bg);
}

#Powerlink .rank .item:hover {
    background: var(--col-gray-1);
}

#Powerlink .rank .item td {
    padding: 8px 12px;
    text-align: center;
    height: 200px;
}

#Powerlink .rank .item td.logData {
    overflow-y: hidden;
    /* 초기에는 스크롤을 숨김 */
    display: flex;
    flex-direction: column;
}

#Powerlink .rank .item td.logData:hover {
    overflow-y: scroll;
    /* 호버 상태일 때 스크롤을 표시 */
}

/* #Powerlink .rank .item td.logData div {
    border-bottom: 1px solid red;
 } */
#Powerlink .rank .item td.logData div p:first-child {
    font-weight: 700;
}

#Powerlink .rank .item td.logData div:last-child {
    border-bottom: 0px;
}


#Powerlink .rank .item td a {
    margin: 0 8px;
    text-decoration: none;
}

#Powerlink .rank .item td a:hover {
    text-decoration: underline;
}

#Powerlink .rank .item .no {
    width: 32px;
    text-align: center;
}

#Powerlink .rank .item .inflowNum {
    width: 48px;
}




/* #Powerlink .rank .item .ratio {
    display: flex;
    flex-direction: row;
    gap: 4px;
    min-width: 240px;
    max-width: 560px;
}

#Powerlink .rank .item .ratio .barWrap {
    display: inline-block;
    width: 100%;
    min-width: 200px;
    max-width: 520px;
    height: 12px;
    background: var(--col-gray-1);
    border: 1px solid var(--col-bg);
}

#Powerlink .rank .item .ratio .barWrap .bar {
    display: block;
    height: 12px;
    background: var(--col-primary-3);
}

#Powerlink .rank .item .ratio .value {
    width: 40px;
} */

#Powerlink .compFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-top: 20px;
    width: 100%;
}

#Powerlink .compFooter .pageBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    background: var(--col-white);
    border: 1px solid var(--col-gray-2);
    border-radius: 4px;
    color: var(--col-gray-4);
}

#Powerlink .compFooter .pageBtn svg {
    fill: var(--col-gray-4);
}

#Powerlink .emptyBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   text-align: center;
}

#Powerlink .start_title{
    font-size: 14px;
    line-height: 16.71px;
    font-weight: 500;
    margin: 0;
    padding-bottom: 12px;
}

#Powerlink .end_title{
    font-size: 14px;
    line-height: 16.71px;
    font-weight: 700;
    margin: 0;
    padding-top: 12px;
}
#Powerlink .check_image{
    width: 100%;
    margin: 0;
}

/* @media (max-width: 1200px) {
    .m_view {
        display: block;
    }
    #Powerlink .rank th:nth-child(5){
        min-width: 130px;
        width: 5%;
        max-width: 580px;
    }
    #Powerlink .rank th:nth-child(2){
        min-width: 120px;
        width: 5%;
        max-width: 580px;
    }
    #Powerlink .rank th:last-child {
        min-width: 130px;
        width: 5%;
        max-width: 580px;
    }
    }
    @media (max-width: 1500px) {
        .m_view {
            display: block;
        }
        #Powerlink .rank th:nth-child(5){
            min-width: 130px;
            width: 5%;
            max-width: 580px;
        }
        #Powerlink .rank th:nth-child(2){
            min-width: 120px;
            width: 5%;
            max-width: 580px;
        }
        #Powerlink .rank th:last-child {
            min-width: 130px;
            width: 5%;
            max-width: 580px;
        }
        } */

@media (max-width: 1200px) {
    #Powerlink {
        width: 100%;
        padding: 0;
    }

    #Powerlink .compHeader {
        padding: 1.5rem;
        padding-bottom: 0.5rem;
    }

    #Powerlink .compBody .rank th,
    #Powerlink .compBody .rank td {
        white-space: nowrap;
        max-width: 2rem;
        overflow: auto;
        text-overflow: clip;
    }

    #Powerlink .rank th:last-child {
        min-width: auto;
        width: auto;
    }

    #Powerlink .rank .item td.logData {
        display: contents;
    }
    #Powerlink .start_title{
        padding-top: 15px;
    }
}

@media (max-width: 768px) {
    #Powerlink .compBody .rank .no {
        display: none;
    }


}