#landingMain {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    background: var(--col-bg);
}
#landingMain .m_view{
    display: none;
}
#landingMain .m_view2{
    display: none;
}
#landingMain .page {
    box-sizing:border-box;
    position: relative;
    display: flex;
    flex-direction: column; /* 아이템을 수직으로 배치 */
    justify-content: center; 
    align-items: center;
    /* gap: 40px; */
    margin: 0;
    padding: 0;
    height: 100vh;
    z-index: 1;
}
#landingMain .page .endBtn{
    font-size: 3.6rem;
    color: #FFFFFF;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
}
#landingMain  .page .endcontent{
    font-size: 3.6rem;
    margin: 0 0 30px 0;
    font-weight: 700;
    color: #FFFFFF;
    text-align: center;
}
#landingMain .firstPageContainer{
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
justify-content: center;
width: 100%;
height: 100%;
}
#landingMain .page:first-child .Logo{
max-width: 383.44px ;
/* max-height: 383.44px; */
width: 100% ;
margin-bottom: 31px ;
}

#landingMain .page:first-child {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    margin: 0;
    padding: 0;
    /* width: 100vw; */
    min-height: 100vh;
    z-index: 1;
    /* background-image: url(./../Images/bg-main.png);
    background-size: cover;
    background-position: center; */
}
#landingMain .page:first-child div .title {
    margin-bottom: 43px;
    margin-top: 3px;
    padding: 0;
    text-align: center;
    line-height: 76.81px;
    font-size: 4rem;
    font-weight: 500;
    letter-spacing: -3px;
    color: var(--col-white);
}
.page .slick-slider .slick-list {
    width: 100%;
    height: 100%;
}

.page .slick-slider .slick-list .slick-track {
    width: 100%;
    height: 100%;
}
.page .slick-slider .slick-list .slick-track div  {
    width: 100%;
    height: 100%;
}
#landingMain .page:nth-child(2n) {
    background-color: #FFFFFF;
}
#landingMain .page:nth-child(2n+1) {
    background-color: var( --col-primary-6);
}
#landingMain .page.contact{
    background-image: url(./../Images/group6.png);
    background-size: cover;
    background-position: center;
    height: 50vh;
}


   #landingMain .page .layout {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
   }

   #landingMain .page.intro{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
   }
   #landingMain .page.intro .title{
    margin: 0;
    font-size: 3.6rem;
    }
   #landingMain .page.intro .subtitle02{
    margin: 0;
    font-size: 0.9rem;
   }
   #landingMain .page.intro .subTitle {
    margin: 0;
    padding: 1% 0;
    text-align: center;
    line-height: 30px;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: -1px;
    color: var(--col-gray-4);
}
   #landingMain .page.intro .layout .content{
    margin: 0;
    padding: 2% 0 0 0;
    display: flex; 
    flex-direction:row;
    justify-content: center; 
    text-align: center;
    max-width: 1170px;
    width: 100%;
    flex-wrap: wrap; 
   }
   #landingMain .page.intro .layout .content p:first-child,
   #landingMain .page.badclickmonit .layout .content p:first-child{
    margin: 0
   }
   #landingMain .page.intro .layout .content p{
    border-radius: 50px;
    padding: 10px 15px;
    margin: 0 0 0 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:  #4C6EE3;
    color: #FFFFFF;
    line-height: 30px;
    font-size: 1.25rem;
    font-weight: 700;
       }
       #landingMain .page.badclickmonit .layout .content p{
        border-radius: 50px;
        padding: 10px 15px;
        margin: 0 0 0 2%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        line-height: 30px;
        font-size: 1.25rem;
        font-weight: 700;
       }
       /* #landingMain .page.intro .layout .content p:first-child{
        margin: 0;
       } */
       #landingMain .page.intro .layout .mainpage{
        width: 760px;
        max-width: 760px;
        display: flex;
        justify-content: center;
        align-items: center;
       }
    
       #landingMain .page.intro img{
        width: 85% ;
        /* height: 100%; */
        border: solid 2px #9A9A9A;
        box-shadow: 4px 4px 4px rgba(0,0,0,0.1)
       }
#landingMain .page.intro .title {
    padding:  0;
    text-align: left;
    line-height: 71px;
    font-size: 4rem;
    font-weight: 700;
    letter-spacing: -3px;
    color: var(--col-black);
}
#landingMain .page.badclickmonit .title  {
     color: #FFFFFF !important;
    padding:  0;
    text-align: left;
    line-height: 71px;
    font-size: 4rem;
    font-weight: 700;
    letter-spacing: -3px;
    color: var(--col-black);
}
#landingMain .page.badclickmonit .subTitle  {
    color: #FFFFFF !important;
}
       #landingMain .page.badclickmonit{
        background-color: #4C6EE3;
       }
       #landingMain .page.badclickmonit .layout .content{
        margin: 0;
        display: flex; 
        flex-direction:row;
        /* justify-content: space-between;  */
        justify-content: center;
        align-items: center;
        /* max-width: 800px; */
        width: 100%;
        flex-wrap: wrap; 
       }

  
       #landingMain .page.badclickmonit .layout .badclick{
        display: flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
        width: 90%;
        padding-bottom: 1%;
        /* box-sizing: border-box;
        top : 0;
        left : 0;
        width:100%; */
       }  
       #landingMain .page.badclickmonit .layout .badclick img:first-child{
        width: 20%;
        box-shadow:4px 4px 4px rgba(0,0,0,0.1);
       }
       #landingMain .page.badclickmonit .layout .badclick img:nth-child(2){
        width: 33.9%;
        box-shadow:4px 4px 4px rgba(0,0,0,0.1);
        margin-left: 1%;
       }
       #landingMain .page.email .layout .emailimage{
        width: 100%;
        text-align: center;
       }
       #landingMain .page.email .layout .emailimage img:first-child{
        width: 20%;
        box-shadow:4px 4px 4px rgba(0,0,0,0.1);
        border: 2px solid #9a9a9a;
       }
       #landingMain .page.email .layout .emailimage img:nth-child(2){
        width: 20%;
        box-shadow:4px 4px 4px rgba(0,0,0,0.1);
        margin-left: 1%;
        border: 2px solid #9a9a9a;
       }
       #landingMain .page.poupular {
        background-color: #FFFFFF;
       }
       #landingMain .page.poupular .lankingpage{
        width: 50%;
        padding: 0;
       }
       #landingMain .page.poupular .lankingpage img{
           width: 100%;
           height: 100%;
           box-shadow:4px 4px 4px rgba(0,0,0,0.1);
       }
       #landingMain .page.visit{
           background-color: var( --col-primary-6);
       }
       #landingMain .page.visit .visitpage {
        width: 50%;

       }
       #landingMain .page.visit img {
        width: 85%;
    margin: 0 auto;
    display: block;
        box-shadow: 4px 4px 4px rgba(0,0,0,0.1)
       }
       #landingMain .page.os .ospage{
        width: 50%;
       }
       #landingMain .page.os img {
        width: 85%;
    margin: 0 auto;
    display: block;
        box-shadow: 4px 4px 4px rgba(0,0,0,0.1)
       }

#landingMain .page .title {
    margin: 0;
    padding: 0;
    text-align: left;
    line-height: 71px;
    font-size: 4rem;
    font-weight: 700;
    letter-spacing: -3px;
    color: var(--col-black);
}


#landingMain .page .subTitle {
    margin: 0 0 0 0;
    padding: 1% 0;
    text-align: center;
    line-height: 30px;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: -1px;
    color: var(--col-gray-4);
}
#landingMain .page .subTitle span{
    font-size: 1.25rem;
    padding: 5px;
    color: #FFFFFF;
    background-color: #4C6EE3
}

#landingMain .page:first-child .subTitle {
    margin-bottom: 40px;
    text-align: center;
    line-height: 30px;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: -1px;
    color: var(--col-gray-1);
}

    #landingMain .page .startBtns{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width:302px
    } 
#landingMain .page .startBtns .startBtn:first-child {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 55%;
    height: 100%;
    /* margin-bottom: 200px; */
    background: rgba(0, 0, 0, 0.8);
    border: none;
    border-radius: 8px;
    outline: none;
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--col-white);
    transition: background .3s;
    cursor: pointer;
}
#landingMain .page  .startBtns .startBtn:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-left: 3%;
    padding: 3%;
    width: 45%;
    height: 100%;
    /* margin-bottom: 200px; */
    background: rgba(0, 0, 0, 0.8);
    border: none;
    border-radius: 8px;
    outline: none;
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--col-white);
    transition: background .3s;
    cursor: pointer;
}
#landingMain .page .startBtn:hover {
    background: rgba(0, 0, 0, 0.7);
}

#landingMain .page .startBtn svg {
    fill: var(--col-white);
}


.fixed-button {
  position: fixed;
  right: 10px;
  bottom: 0px;
  height: 50px; 
  z-index: 999; 
  padding: 10px 20px;
  background-color: #2C97DE;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transform: translate(-50%, -50%);
}
#mouse-scroll {
    display: block;
    position: absolute;
    bottom: 64px;
    margin: auto;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 9999;
}

#mouse-scroll span {
    display: block;
    width: 5px;
    height: 5px;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transform: rotate(45deg);
    border-right: 2px solid var(--col-white);
    border-bottom: 2px solid var(--col-white);
    margin: 0 0 3px 5px;
}

#mouse-scroll .mouse {
    height: 21px;
    width: 14px;
    border-radius: 10px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    border: 2px solid var(--col-white);
    top: 170px;
}

#mouse-scroll .down-arrow-1 {
    margin-top: 6px;
}

#mouse-scroll .down-arrow-1,
#mouse-scroll .down-arrow-2,
#mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
    animation: mouse-scroll 1s infinite;
}

#mouse-croll .down-arrow-1 {
    -webkit-animation-delay: .1s;
    -moz-animation-delay: .1s;
    -webkit-animation-direction: alternate;
animation-delay: .1s;
    animation-direction: alternate;
}

#mouse-scroll .down-arrow-2 {
    -webkit-animation-delay: .2s;
    -moz-animation-delay: .2s;
    -webkit-animation-direction: alternate;
    animation-delay: .2s; 
    animation-direction: alternate;
}

#mouse-scroll .down-arrow-3 {
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
    -moz-animation-dekay: .3s;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

#mouse-scroll .mouse-in {
    height: 5px;
    width: 2px;
    display: block;
    margin: 5px auto;
    background: var(--col-white);
    position: relative;
}

#mouse-scroll .mouse-in {
    -webkit-animation: animated-mouse 1.2s ease infinite;
    animation: animated-mouse 1.2s ease infinite;
}

@keyframes animated-mouse {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(6px);
    }
}

@keyframes mouse-scroll {
    0% {
        opacity: 1;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes mouse-scroll {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}
#landingMain .m_view4 {
    display: none;
}
@media all and (max-width:968px){
body{
    min-width: 0px;
}
#landingMain .m_view {
    display: block;
}
#landingMain .page.intro .layout .content p:first-child, 
#landingMain .page.badclickmonit .layout .content p:first-child {
    margin-bottom: 1% ;
}
#landingMain .page.intro .layout .content P{
    margin-bottom: 1% ;
   }
   #landingMain .page.badclickmonit .layout .content P{
    margin-bottom: 1%;
   }
   #landingMain .page.intro img{
    width: 80% ;
   }
#landingMain .page.badclickmonit .layout .badclick img:first-child{
    width: 30%;
   }
   #landingMain .page.badclickmonit .layout .badclick img:nth-child(2){
    width: 50.9%;
   }
   #landingMain .page.poupular .lankingpage{
    width: 80%;
    padding: 2% 0 0 0;
   }
   #landingMain .page.visit .visitpage {
    width: 80%;
   }
   #landingMain .page.os .ospage{
    width: 80%;
   }
   #landingMain .page.email .layout .emailimage img:first-child{
    width: 35%;
   }
   #landingMain .page.email .layout .emailimage img:nth-child(2){
    width: 35%;
   }
}


@media  all and (max-width: 600px) {
    #landingMain .m_view1 {
        display: none;
    }
    #landingMain .m_view4 {
        display: block;
    }
    #landingMain .page{
        height: 100%;
        padding: 80px 20px;
    }
    #landingMain .page:first-child .Logo {
        max-width: 383.44px;
        width: 50%;
        margin-bottom: 30px;
    }
    #landingMain .page .title {
        font-size: 2rem;
    }
    #landingMain .page:first-child div .title {
        line-height: 31.81px;
        font-size: 2rem;
        margin-bottom: 50px;
    }
    #landingMain .page .startBtns .startBtn {
        font-size: 1rem;
        padding: 3% 0;
    }
    #landingMain .page .subTitle {
        font-size: 1.1rem;
        padding: 15px 0;
    }
    #landingMain .page .subTitle span {
        font-size: 1.1rem;
        /* padding: 15px 0; */
        line-height: 25px;
    }
    #landingMain .page.intro .subTitle {
        font-size: 1.1rem;
        padding: 15px 0;
        line-height: 25px;
    }
    #landingMain .page.intro .layout .mainpage {
        width:  100%;
    }
    #landingMain .page.intro .layout .content {
        justify-content: space-between;
        width: 90%;
        margin-top: 15px;
        flex-direction: row;
    }
    #landingMain .page.intro .layout .content p{
        margin: 0 0 2% !important;
        width: 35%;
        font-size: 1rem;
    }
    #landingMain .page.badclickmonit .layout .content {
        justify-content: space-between;
        width: 90%;
        margin-top: 15px;
        flex-direction: row;
    }
    #landingMain .page.badclickmonit .layout .content p {
        margin: 0 0 2% !important;
        width: 35%;
        font-size: 1rem;
    }
    #landingMain .page.intro img {
        width: 100%;
        /* margin: 2% 0; */
    }
    #landingMain .page.intro .title {
        font-size: 3rem;
        margin-bottom: -3%;
    }
    #landingMain .page.intro .subtitle02 {
        font-size: 1rem;
        padding-bottom: 3%;
        margin: 0;
    }
    #landingMain .page.badclickmonit .title {
        font-size: 2rem;
    }
    #landingMain .page.badclickmonit .subTitle {
        font-size: 1.1rem;
        padding: 15px 0;
        line-height: 25px;
    }

    #landingMain .page.badclickmonit .layout .badclick {
        width: 100%;
        padding-bottom: 5%;
    }
    #landingMain .page.badclickmonit .layout .badclick img:first-child{
        width: 36.5%;
    }
    #landingMain .page.badclickmonit .layout .badclick img:nth-child(2){
    width: 61.9%;
    }
    #landingMain .page.poupular .lankingpage {
        width: 100%;
        padding: 0;
    }
    #landingMain .page.visit .visitpage {
        width: 100%;
    }
    #landingMain .page.visit img {
        width: 100%;
    }
    #landingMain .page.os .ospage {
        width: 100%;
    }
    #landingMain .page.os img {
        width: 100%;
    }
    #landingMain .page .endcontent {
        font-size: 1.5rem;
    }
    #landingMain .page .endBtn {
        font-size: 1.5rem;
    }
    #landingMain .page:first-child {
        min-height: 50vh;
    }
    .slider-item{
        width: 100%; 
    }
    .slider-item img{
     object-fit: cover;
    }
    .slider-slick-track img{
        max-width: 100%;
        height: auto;
    }
    #landingMain .page.email .layout .emailimage img:first-child{
        width: 48%;
       }
       #landingMain .page.email .layout .emailimage img:nth-child(2){
        width: 48%;
       }
}
