#sidebar {
    position: sticky;
    top: 0;
    min-width: 240px;
    width: 280px;
    min-height: 100vh;
    /* max-height: 100vh; */
    overflow: hidden;
    /* height: 100%; */
    background: var(--col-white);
    box-shadow: 0px 0px 12px var(--col-gray-1);
    overflow: scroll;
    scrollbar-width: none;
}

#sidebar::-webkit-scrollbar {
    display: none;
}

#sidebar .logo {
    margin: 20px 20px 0 20px;
    cursor: pointer;
}

#sidebar .logo img {
    width: 160px;
}

#sidebar .menu .step1 {
    list-style-type: none;
    padding-left: 20px;
}

#sidebar .menu .step1 li {
    margin: 20px 0;
    font-size: 12px;
    font-weight: 700;
    color: var(--col-black);
}

#sidebar .menu .step2 {
    list-style: none;
    padding-left: 8px;
}

#sidebar .menu .step2 li {
    margin: 12px 0;
    font-size: 16px;
    font-weight: 400;
    color: var(--col-gray-4);
    cursor: pointer;
}

#sidebar .menu .step2 li::before {
    content: " · ";
}

#sidebar .menu .step2 li:hover {
    font-weight: 600;
    color: var(--col-primary-2)
}

#sidebar .menu .step2 .sel,
#sidebar .menu .step2 .sel:hover,
#sidebar .menu .step2 strong,
#sidebar .menu .step2 strong:hover {
    font-size: 16px;
    font-weight: 700;
    color: var(--col-primary-1)
}

#sidebar .developing {
    filter: blur(1.5px);
    opacity: .3;
    text-decoration: line-through;
}

@media (max-width: 1250px) {
    #sidebar {
        position: fixed;
        top: 0;
        bottom: 0;

        overflow: visible;

        z-index: 1001;

        transition: 0.4s ease;
    }

    #sidebar .sidebar_drawer {
        position: fixed;
        top: 1rem;
        right: -1rem;

        height: 40px;

        background-color: var(--col-info);
        color: white;

        border-radius: 0 10px 10px 0;
        border: none;
    }

    #sidebar .menu .step1 {
        padding-bottom: 10rem;
    }
}

.mobile_sidebar {
    display: none; /* 기본적으로 숨김 */
}

/* 1200px 이하일 때 스타일 */
@media (max-width: 1250px) {
    .mobile_sidebar {
        display: block; /* 1200px 이하일 때 보임 */
    }
}